<template>
  <div class="login">
    <div class="login-card">
      <h2>Hello, {{ username }}!</h2>
      <el-input placeholder="请输入用户名"
                v-model="username">
        <template #prepend>用户</template>
      </el-input>
      <el-input placeholder="请输入密码"
                v-model="password">
        <template #prepend>密码</template>
      </el-input>
      <el-button @click="handleLogin">登 陆</el-button>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/runtime-core'
import { post } from '../../http/axios'
import router from '../../router/index'

export default {
  setup () {
    const username = ref('charles')
    const password = ref('')

    const handleLogin = () => {
      const data = {
        username: username.value,
        password: password.value
      }
      post('/api/user/login', data, '', '登陆失败，请重试').then(data => {
        router.push('/')
      }).catch(err => {
        console.log(err)
      })
    }

    return {
      username,
      password,
      handleLogin
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  position: relative;
  height: 100%;

  .login-card {
    position: absolute;
    width: 480px;
    height: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 40px 80px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
