<template>
  <div class="about">
    <h2>偷懒中...</h2>
  </div>
</template>

<style scoped>
.about {
  text-align: center;
}
</style>
